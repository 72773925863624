import React, { Component } from 'react'
import SEO from '../components/seo'

class NotFoundPage extends Component {
  render() {
    return (
      <div>
        <SEO />
        <span>404 not found</span>
      </div>
    )
  }
}
export default NotFoundPage
